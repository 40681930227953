body {
  font-family: "Open Sans";
  margin: 0px;
  transition: all 0.3s ease-in-out;
}
a {
  text-decoration: none;
}
:is(h1, h2, h3, h4, h5, h6) {
  margin: 0px;
}

::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: #a3b0c2;
  border-radius: 5px;
}

:root {
  --theme-bg: #e5e5e5;
  --theme-purple: #6c2eb9;
  --theme-gray: #1d2327;
  --theme-card-bg: #f1f5f9;
  --theme-font-primary: #32373c;
  --theme-font-secondary: #4c5866;
  --theme-blue: #0066ff;
  --theme-blue-hover: #0056d8;
  --theme-red: #f12424;
  --theme-cyan: #29c4a9;
  --theme-input-focus: #e6ecf2;
  --theme-placeholder: #a3b0c2;
  --theme-gray-secondary-menu: #252d32;
  --theme-hover-bg: #e1e7ec;
}
.box-shadow {
  box-shadow: 0px 5px 30px rgba(43, 135, 218, 0.2);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 0px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
.tox-notifications-container,
.tox-menubar,
.tox-statusbar {
  display: none !important;
}
.tox-toolbar__primary,
.tox-editor-header,
.tox-toolbar-overlord {
  background-color: var(--theme-card-bg) !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  padding: 0px !important;
}
.tox-sidebar-wrap {
  border: 2px solid var(--theme-card-bg) !important;
  border-radius: 5px !important;
}
.tox-tinymce,
.tox-editor-container {
  border: none !important;
}
.tox-editor-container {
  gap: 3px !important;
}
.tox-tinymce {
  border-radius: 5px !important;
}
.tox .tox-tbtn__select-label {
  cursor: pointer !important;
}
.tox .tox-tbtn:not(:first-child) {
  cursor: pointer !important;
  width: 28px !important;
  margin: 6px 3px !important;
}
.tox .tox-tbtn svg {
  fill: #4c5866 !important;
}

.tox .tox-tbtn--bespoke {
  background-color: var(--theme-card-bg) !important;
  cursor: pointer !important;
}
.tox .tox-tbtn--bespoke:hover {
  background-color: #cce2fa !important;
}
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background-image: none !important;
}
.tox .tox-edit-area__iframe {
  border-radius: 5px !important;
}
.card-div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 360px;
  width: 100%;
  gap: 10px;
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 0;
  justify-content: space-between;
}
.color-box {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
}
.max-box-full {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.26s ease;
}

/* .editor div {
  border: 0px !important;
  word-break: break-all; */
/* } */

ol,
ul {
  list-style: revert !important;
  margin: auto !important;
  padding: inherit !important;
}
.react-colorful__saturation {
  border-radius: 0px !important;
}
.react-colorful__hue {
  height: 12px !important;
  margin: 4px 0px !important;
  border-radius: 15px !important;
}
.react-colorful__pointer {
  width: 12px !important;
  height: 12px !important;
}

#active {
  background-color: #364149;
}
#active:hover {
  background-color: var(--theme-gray);
}
.body-container {
  padding: 24px;
  height: calc(65vh - 50px);
  /* changed calc from 70 to 65 */
  overflow-y: auto;
  overflow-x: hidden;
}
.menu-card {
  height: 40px;
  background-color: var(--theme-card-bg);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 13px;
  transition: all 0.3s ease-in-out;
}
.menu-card:where(h2, path) {
  transition: all 0.3s ease-in-out;
}
.menu-card:hover {
  background-color: var(--theme-hover-bg);
  transition: all 0.3s ease-in-out;
}
.menu-card-icon {
  width: 18px;
  height: 19px;
  min-height: 19px;
  width: 18px;
}

.home-screen-container {
  background-color: var(--theme-card-bg);
  height: min(100dvh, 100%);
  overflow-x: hidden;
}
.right-side-container {
  border-radius: 5px;
  margin: 30px;
  flex: 1;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.bg-theme-card {
  background-color: var(--theme-card-bg);
}
.primary-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: var(--theme-gray);
  text-transform: capitalize;
  margin: 0px;
  word-break: break-word;
}
.secondary-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--theme-gray);
  margin: 0px;
  word-break: break-word;
}
.clamp-text {
  display: -webkit-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-box-orient: vertical;
  -moz-line-clamp: 1;
  overflow: hidden;
}
.clamp-1 {
  -webkit-line-clamp: 1;
}

.clamp-2 {
  -webkit-line-clamp: 2;
}

.clamp-3 {
  -webkit-line-clamp: 3;
}
.body-text {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: var(--theme-font-secondary);
  word-break: break-word;
  margin: 10px 0px;
}
.input-field-div {
  border-radius: 5px;
  border: 2px solid #f1f5f9;
  padding: 4px 10px;
  font-size: 13px;
  line-height: 16px;
  color: var(--theme-font-secondary);
  margin: 7px 0px;
  display: flex;
  align-items: center;
  width: inherit;
  position: relative;
}
.input-search-icon {
  margin-right: 10px;
  min-width: 11px;
  min-height: 11px;
}
.input-field {
  border: none;
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  color: var(--theme-font-secondary);
  font-weight: 600;
}
input.input-field::placeholder {
  font-size: 13px;
  line-height: 16px;
  color: var(--theme-placeholder);
  font-weight: 600;
}

.input-field:focus {
  outline: none;
}
.input-field-div:focus-within {
  border: 2px solid var(--theme-blue);
}
.input-field::placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: var(--theme-font-secondary);
}
.roles-permission-heading {
  font-weight: 600;
  font-size: 17px;
  line-height: 28px;
  color: var(--theme-gray);
  margin: 18px 0px;
}
.permission-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0px;
}
.permission-card-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.page-title-heading {
  color: var(--theme-gray);
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  border: 1px solid var(--theme-card-bg);
}
.save-button {
  background-color: var(--theme-blue);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  padding: 5.5px 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: 0px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}
.save-button:hover {
  background-color: var(--theme-blue-hover);
  transition: all 0.3s ease-in-out;
}
.save-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.button-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}
.back-arrow {
  height: 22px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13.5px 24px 13.5px 20px;
  border-bottom: solid 2px var(--theme-card-bg);
}
.header-container .primary-heading {
  display: -webkit-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-box-orient: vertical;
  -moz-line-clamp: 1;
  overflow: hidden;
}
.status-card-preview {
  background-color: var(--theme-card-bg);
  width: max-content;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 7px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status-card-preview-dataIcon {
  padding: 0px 10px;
}
.status-card-color-circle {
  height: 10px;
  min-height: 10px;
  min-width: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 4px 10px 4px 0px;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-1 {
  margin-top: 5px !important;
}
.mt-1-5 {
  margin-top: 7px !important;
}
.-mt-2 {
  margin-top: -10px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mt-4 {
  margin-top: 20px !important;
}
.mt-4-5 {
  margin-top: 22px !important;
}
.mt-5 {
  margin-top: 25px !important;
}
.mt-6 {
  margin-top: 30px !important;
}
.\!mt-0 {
  margin-top: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-1 {
  margin-bottom: 5px !important;
}
.mb-1-5 {
  margin-bottom: 7px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.mb-3 {
  margin-bottom: 15px !important;
}
.mb-3-5 {
  margin-bottom: 17px !important;
}
.mb-4 {
  margin-bottom: 20px !important;
}
.mb-5 {
  margin-bottom: 25px !important;
}
.mb-6 {
  margin-bottom: 30px !important;
}
.ml-1 {
  margin-left: 5px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.ml-4 {
  margin-left: 20px !important;
}
.ml-5 {
  margin-left: 25px !important;
}
.ml-6 {
  margin-left: 30px !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mr-3 {
  margin-right: 15px !important;
}
.mr-4 {
  margin-right: 20px !important;
}
.mr-5 {
  margin-right: 25px !important;
}
.mr-6 {
  margin-right: 30px !important;
}
.mr-7 {
  margin-right: 35px !important;
}
.mr-8 {
  margin-right: 40px !important;
}
.mr-9 {
  margin-right: 45px !important;
}
.mr-10 {
  margin-right: 50px !important;
}
.mr-auto {
  margin-right: auto !important;
}
.m-0 {
  margin: 0px !important;
}
.\!m-0 {
  margin: 0px !important;
}
.border-line {
  background-color: var(--theme-card-bg);
  height: 2px;
  width: 100%;
  border: 0px;
}
.text-17 {
  font-size: 17px;
  line-height: 28px;
}
.text-13 {
  font-size: 13px;
}
.text-14 {
  font-size: 14px;
  line-height: 20px;
}

.logo-input {
  width: 100%;
  border: 2px dashed var(--theme-card-bg);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 24px 0px;
  gap: 5px;
}
.logo-input-preview {
  max-width: 300px;
  width: inherit;
  /* height: 100px;
  border-radius: 50%; */
}
.logo-input-preview-background {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  margin: 5px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.logo-input .relative:hover .logo-input-preview-background {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.logo-input-preview-background:hover {
  box-shadow: 0px 5px 30px rgba(43, 135, 218, 0.2);
}
.relative {
  position: relative;
}
.relative-important {
  position: relative !important;
}
.absolute {
  position: absolute;
}
.top-0 {
  top: 0%;
}
.top-half {
  top: 50%;
}
.-top-half {
  top: 50%;
}
.-top-full {
  top: 100%;
}
.top-full {
  top: 100%;
}
.bottom-0 {
  bottom: 0%;
}
.bottom-half {
  bottom: 50%;
}
.-bottom-half {
  bottom: -50%;
}
.-bottom-full {
  bottom: -100%;
}
.bottom-full {
  bottom: 100%;
}
.left-0 {
  left: 0%;
}
.left-half {
  left: 50%;
}
.-left-half {
  left: 50%;
}
.-left-full {
  left: 100%;
}
.left-full {
  left: 100%;
}
.right-0 {
  right: 0%;
}
.right-half {
  right: 50%;
}
.-right-half {
  right: 50%;
}
.-right-full {
  right: 100%;
}
.right-full {
  right: 100%;
}
.secondary-button {
  border: none;
  padding: 5.5px 10px;
  margin: 0px;
  color: #4c5866;
  background: #f1f5f9;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: 0px;
  transition: all 0.3s ease-in-out;
}
.text-white {
  color: white;
}
.text-black {
  color: black;
}
.list-card-container,
.list-card-container-selected {
  background-color: var(--theme-card-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  margin: 10px 0px;
}
.list-card-container:hover {
  background-color: var(--theme-hover-bg);
}
.list-card-container a {
  padding: 0px 6px;
}
.list-card-container-selected {
  border: 2px solid var(--theme-blue);
}
.list-card-right-container {
  display: flex;
  align-items: center;
  padding-right: 6px;
  height: 100%;
}
.list-card-secondary-data {
  color: var(--theme-placeholder);
  font-size: 11px;
  font-weight: normal;
  margin-left: 7.5px;
  margin-right: 7.5px;
}
.list-card-icon {
  padding-left: 7.5px;
  padding-right: 7.5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.list-card-delelte-popup {
  position: absolute;
  background-color: white;
  width: 340px;
  right: -3px;
  z-index: 5;
  border-radius: 5px;
}
.rounded-3 {
  border-radius: 3px !important;
}
.rounded-5 {
  border-radius: 5px !important;
}
.bg-theme-blue {
  background-color: var(--theme-blue);
}
.bg-theme-gray {
  background-color: var(--theme-gray);
}
.bg-transparent {
  background-color: transparent;
}
.bg-theme-card-bg {
  background-color: var(--theme-card-bg);
}
.bg-theme-red {
  background-color: var(--theme-red);
}
.list-grid-card {
  background-color: var(--theme-card-bg);
  border-radius: 5px;
  max-width: 263px;
  min-width: 263px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.list-grid-card > img {
  width: 100%;
}
.list-grid-card > div {
  padding: 0px 6px;
}
.list-grid-card > div:hover {
  background-color: var(--theme-hover-bg);
  transition: all 0.2s ease-in-out;
}
.loader-container {
  background: rgb(55 65 81 / 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}
.block-loader {
  background: rgb(55 65 81 / 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}
.loader {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid rgb(64, 62, 62);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.list-icon-wrapper {
  min-width: 28px;
  min-height: 28px;
  width: 28px;
  height: 28px;
  margin: 6px 0px;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.gap-1 {
  gap: 1px;
}
.gap-2 {
  gap: 2px;
}
.\!gap-2 {
  gap: 2px !important;
}
.gap-3 {
  gap: 3px;
}
.gap-4 {
  gap: 4px;
}
.gap-5 {
  gap: 5px;
}
.gap-6 {
  gap: 6px;
}
.gap-7 {
  gap: 7px;
}
.gap-8 {
  gap: 8px;
}
.gap-9 {
  gap: 9px;
}
.gap-10 {
  gap: 10px;
}
.gap-11 {
  gap: 11px;
}
.gap-12 {
  gap: 12px;
}
.gap-13 {
  gap: 13px;
}
.gap-14 {
  gap: 14px;
}
.gap-15 {
  gap: 15px;
}
.gap-16 {
  gap: 16px;
}
.gap-17 {
  gap: 17px;
}
.gap-18 {
  gap: 18px;
}
.gap-19 {
  gap: 19px;
}
.gap-20 {
  gap: 20px;
}
.gap-28 {
  gap: 28px;
}
.gap-0 {
  gap: 0px;
}
.flex-1 {
  flex: 1 1 0%;
}
.w-full {
  width: 100%;
}
.w-1\/2 {
  width: 50%;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.w-screen {
  width: 100vw;
}
.border-0 {
  border: none !important;
}
.border-white {
  border: 1px solid white;
}
.px-1 {
  padding-left: 5px;
  padding-right: 5px;
}
.px-2 {
  padding-left: 10px;
  padding-right: 10px;
}
.px-2-5 {
  padding-left: 12px;
  padding-right: 12px;
}
.px-3 {
  padding-left: 15px;
  padding-right: 15px;
}
.px-4 {
  padding-left: 20px;
  padding-right: 20px;
}
.px-5 {
  padding-left: 25px;
  padding-right: 25px;
}
.px-6 {
  padding-left: 30px;
  padding-right: 30px;
}
.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}
.py-1 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-2-5 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.py-3 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.py-4 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-5 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.py-6 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: 5px;
}
.p-\[1px\] {
  padding: 1px;
}
.p-\[6px\] {
  padding: 6px;
}
.p-2 {
  padding: 10px;
}
.p-3 {
  padding: 15px;
}
.p-4 {
  padding: 20px;
}
.p-5 {
  padding: 24px;
}
.p-6 {
  padding: 30px;
}
.pt-1 {
  padding-top: 5px;
}
.pt-2 {
  padding-top: 10px;
}
.pt-3 {
  padding-top: 15px;
}
.pt-4 {
  padding-top: 20px;
}
.pt-5 {
  padding-top: 24px;
}
.pb-1 {
  padding-bottom: 5px;
}
.pb-2 {
  padding-bottom: 10px;
}
.pb-3 {
  padding-bottom: 15px;
}
.pb-4 {
  padding-bottom: 20px;
}
.pb-5 {
  padding-bottom: 24px;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.error-text {
  color: var(--theme-red);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}
.success-text {
  color: green;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}
.text-\[\#4C5866\] {
  color: #4c5866;
}
.font-size-10 {
  font-size: 10px;
}
.font-size-11 {
  font-size: 11px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-17 {
  font-size: 17px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-22 {
  font-size: 22px;
}
.font-normal {
  font-weight: 400 !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.font-extrabold {
  font-weight: 800 !important;
}
.font-black {
  font-weight: 900 !important;
}
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.break-all {
  word-break: break-all;
}
.break-word {
  word-break: break-word;
}
.text-white {
  color: #fff;
}
.text-black {
  color: #000;
}
.text-gray {
  color: var(--theme-font-secondary);
}
.text-placeholder-gray {
  color: var(--theme-placeholder);
}
.fill-white {
  fill: #fff;
}
.fill-black {
  fill: #000;
}
.fill-gray {
  fill: var(--theme-font-secondary);
}
.w-fit {
  width: fit-content;
}
.mt-1-5 {
  margin-top: 7px;
}
.py-1-5 {
  padding-top: 7px;
  padding-bottom: 7px;
}
.min-w-18rem {
  min-width: 288px;
}
.max-w-20rem {
  max-width: 320px;
}
.max-w-25rem {
  max-width: 400px;
}
.left-2 {
  left: 0.5rem /* 8px */;
}
.bg-white {
  background-color: #fff;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.cls-1 {
  fill: #00d263 !important;
}
.cls-2 {
  fill: #06f !important;
  fill-rule: evenodd;
}
.cursor-default {
  cursor: default;
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.h-10 {
  height: 2.5rem /* 40px */;
}
.h-8 {
  height: 2rem /* 32px */;
}
.h-6 {
  height: 1.5rem /* 24px */;
}
.h-2 {
  height: 0.5rem /* 8px */;
}
.h-3 {
  height: 0.75rem /* 12px */;
}
.h-20 {
  height: 5rem /* 80px */;
}
.h-24 {
  height: 6rem /* 96px */;
}
.h-44 {
  height: 11rem /* 176px */;
}
.h-4 {
  height: 1rem /* 16px */;
}
.h-4-5 {
  height: 1.125rem /* 18px */;
}
.min-h-4 {
  min-height: 1rem /* 16px */;
}
.h-5 {
  height: 1.25rem /* 20px */;
}
.min-w-4 {
  min-width: 1rem /* 16px */;
}
.w-4 {
  width: 1rem /* 16px */;
}
.w-5 {
  width: 1.25rem /* 20px */;
}
.w-14 {
  width: 3.5rem /* 56px */;
}
.w-20 {
  width: 5rem /* 80px */;
}
.w-24 {
  width: 6rem /* 96px */;
}
.w-28 {
  width: 7rem /* 112px */;
}
.w-32 {
  width: 8rem /* 128px */;
}
.w-36 {
  width: 9rem /* 144px */;
}
.w-40 {
  width: 10rem /* 160px */;
}
.w-60 {
  width: 15rem /* 240px */;
}
.text-11 {
  font-size: 11px;
}
.text-theme-placeholder {
  color: var(--theme-placeholder) !important;
}
.text-theme-secondary {
  color: var(--theme-font-secondary) !important;
}
.line {
  background-color: var(--theme-card-bg);
  height: 2px;
  border: 0px;
}
.self-center {
  align-self: center;
}
.leading-1 {
  line-height: 0.25rem;
}
.leading-2 {
  line-height: 0.5rem;
}
.leading-3 {
  line-height: 0.75rem;
}
.leading-4 {
  line-height: 1rem /* 16px */;
}
.leading-5 {
  line-height: 1.25rem /* 20px */;
}
.leading-6 {
  line-height: 1.5rem /* 24px */;
}
.text-theme-blue {
  color: var(--theme-blue);
}
.text-primary {
  color: var(--theme-gray);
}
.underline {
  text-decoration-line: underline;
}
.no-underline {
  text-decoration-line: none;
}
.py-\[7\.5px\] {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}
.w-max {
  width: max-content;
}
.avatar {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--theme-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.avatar h2 {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
}
.rounded-full {
  border-radius: 9999px !important;
}
.code-textarea {
  width: 100%;
  border: 2px solid #f1f5f9;
  border-radius: 5px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #4c5866;
  resize: vertical;
  padding: 7px 10px;
  min-height: 100px;
}
.code-textarea:focus-within,
.code-textarea:focus,
.code-textarea:focus-visible {
  border: 2px solid var(--theme-blue);
  outline: none;
}
.code-textarea::placeholder {
  font-size: 13px;
  line-height: 16px;
  color: var(--theme-placeholder);
  font-weight: 600;
}
.transition-all {
  transition: all 0.3s ease-in-out;
}
.border-b-theme-card-bg {
  border-bottom: 1px solid var(--theme-card-bg);
}
.list-style-none {
  list-style: none !important;
}
.none {
  display: none;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-20 {
  z-index: 20;
}
.delete-icon-layer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
  height: 100%;
  width: 100%;
  background: transparent;
}
.explainer-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #a3b0c2;
  margin: 1px 0px 0px 0px;
  text-transform: initial;
}
.add-pop-up::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  filter: drop-shadow(0px 5px 30px rgba(43, 135, 218, 0.2));
}
.add-pop-up {
  position: absolute;
  top: 140%;
  right: 0;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(43, 135, 218, 0.2);
  border-radius: 3px;
  width: 280px;
}
.add-pop-up-content {
  background: #f1f5f9;
  border-radius: 3px;
  align-self: stretch;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}
.add-pop-up-content:hover {
  background: #0066ff;
}
.add-pop-up-content-icon {
  width: 26px;
  height: 26px;
  background: #0066ff;
  border: 1px solid #ffffff;
  border-radius: 99999px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-pop-up-content-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #4c5866;
  transition: all 0.3s ease-in-out;
}
.add-pop-up-content:hover .add-pop-up-content-title {
  color: #ffffff;
}
.add-pop-up-content:hover .add-pop-up-content-arrow path {
  fill: #ffffff;
}
.add-pop-up-content-arrow {
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  transition: all 0.3s ease-in-out;
}
.add-pop-up-content-arrow path {
  fill: #4c5866;
}
.list-pop-up {
  position: absolute;
  top: 140%;
  right: 0;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0px 5px 30px rgba(43, 135, 218, 0.2); */
  filter: drop-shadow(0px 5px 30px rgba(43, 135, 218, 0.2));
  border-radius: 3px;
  width: 280px;
  height: fit-content;
}
.list-pop-up-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
.-translate-x-half {
  transform: translateX(-50%);
}
.delete-button-red {
  background-color: var(--theme-red);
}
.delete-button-red:hover {
  background-color: var(--theme-red);
}
.bg-cover {
  background-size: cover;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-center {
  background-position: center;
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.max-h-20 {
  max-height: 5rem !important /* 80px */;
}
.max-h-32 {
  max-height: 8rem !important /* 128px */;
}
.max-h-40 {
  max-height: 10rem !important /* 160px */;
}
.max-h-48 {
  max-height: 12rem !important /* 192px */;
}
.max-h-56 {
  max-height: 14rem !important /* 224px */;
}
.max-h-64 {
  max-height: 16rem !important /* 256px */;
}
.overflow-y-auto {
  overflow-y: auto;
}
.opacity-50 {
  opacity: 0.5;
}
.users-limit-card {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: inherit;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--theme-card-bg);
  margin: 24px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  gap: 15px;
  align-items: start;
  flex-direction: column;
  background: white;
  z-index: 1;
}
