.toggle-card {
  width: 42px;
  height: 26px;
  padding: 0px 4px;
  border-radius: 9999px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.toggle-card > div {
  padding: 1px 0px;
}
.bg-disabled {
  background-color: #f1f5f9;
}
.bg-active {
  background-color: #0066ff;
}
.yes-text {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  padding-left: 6px;
}
.no-text {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #a3b0c2;
  transition: all 0.3s ease-in-out;
  padding-right: 6px;
}
.white-move-button {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 9999px;
  width: 18px;
  height: 18px;
  position: absolute;
  transition-property: all;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.translate-x-0 {
  transform: translateX(0px);
}
.translate-x-full {
  transform: translateX(24px);
}
.filter-button {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
}
.notification {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 7px 6px 10px;
  gap: 10px;
  width: 293px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(43, 135, 218, 0.2);
  border-radius: 5px;
  margin-bottom: 10px;
  height: fit-content;
}
.notification-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  z-index: 100;
}
.hidden {
  animation: SlideRight 0.4s;
  animation-fill-mode: forwards;
}
.open {
  animation: SlideLeft 0.4s;
  animation-fill-mode: forwards;
}
.notification-icon {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}
@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
}

.mce-content-body p {
  font-size: 13px;
  line-height: 16px;
  color: #4c5866;
  font-weight: 600;
}
.template-notfound-containter {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.filter-dropdown {
  max-width: 350px;
  width: 100%;
  background: white;
  border-radius: 5px;
  position: absolute;
  top: 100%;
  right: 0;
  filter: drop-shadow(0px 5px 30px rgba(43, 135, 218, 0.2));
  z-index: 1;
}
.dropdown-card {
  max-width: 350px;
  width: 100%;
  background: white;
  border-radius: 5px;
  position: absolute;
  filter: drop-shadow(0px 5px 30px rgba(43, 135, 218, 0.2));
  z-index: 1;
  right: 0;
  bottom: 100%;
}
.dropdown-list {
  max-height: 200px;
  overflow-y: auto;
  padding: 10px 0px;
}
.dropdown-item {
  padding: 6px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.ddropdown-item-check path {
  fill: #64c1ab;
  transition: all 0.3s ease-in-out;
}
.dropdown-item:hover {
  background: #0066ff;
  color: white;
  transition: all 0.3s ease-in-out;
}
.dropdown-item-check:hover path {
  fill: white;
  transition: all 0.3s ease-in-out;
}
.dropdown-item-check {
  margin-left: auto;
}
.dropdown-item-circle {
  min-width: 12px;
  min-height: 12px;
  width: 12px;
  height: 12px;
  border-radius: 9999px;
  background: #f15a24;
}
.dropdown-item-icon {
  padding: 1px 10px;
}
.select-field {
  width: 100%;
  height: 26px;
  border: 2px solid #f1f5f9;
  border-radius: 5px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}
.select-dropdown {
  width: 102%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: absolute;
  top: -5%;
  left: -1%;
  filter: drop-shadow(0px 5px 30px rgba(43, 135, 218, 0.2));
  z-index: 2;
  background-color: white;
  max-height: 300px;
  overflow-y: auto;
}
.hide-dropdown {
  display: none;
}
.select-dropdown > div {
  padding: 24px;
  width: 100%;
}
.select-dropdown-item {
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 10px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  width: auto;
  font-weight: 600;
  font-size: 13px;
  color: #4c5866;
}
.select-dropdown-item:hover {
  background: #0066ff;
  color: white;
  transition: all 0.3s ease-in-out;
}
.select-dropdown-item:hover path {
  fill: white;
}
.invalid-url {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.checkbox {
  width: 100%;
  height: 100%;
  max-width: 25px;
  max-height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 5px;
  border: 2px solid #a3b0c2;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.selected {
  border: 2px solid #00d263;
  background: #00d263;
}

.accordion-body {
  transition: all 0.5s ease-in-out;
  transform-origin: top;
  display: none;
}
.accordion-body[aria-expanded="true"] {
  transition: all 0.5s ease-in-out;
  display: block;
}
