.notification-container {
  display: flex;
  flex-direction: column;
}
.notification-header-container {
  padding: 16.5px 20px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 58px;
}
.notification-header-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #1d2327;
}
.notification-header-button {
  background-color: #0066ff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: white;
  font-weight: 600;
  font-size: 13px;
  border-radius: 5px;
  padding: 5.5px 10px;
}
.notification-body-title {
  font-weight: 600;
  font-size: 17px;
  line-height: 28px;
  color: #32373c;
  margin: 10px 0px;
}
.notification-detail-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 28px;
}
.notification-detail-header h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #32373c;
  width: 55px;
}
.notification-list-card{
    padding: 10px  0px;
}